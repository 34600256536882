import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import withFirebase from "../../hoc/withFirebase";

import "../../static/scss/components/_report.scss";

import REPORT_ICON from "../../static/images/report-icon.svg";
import NAV_LOGO from "../../static/images/nav_logo.svg";

const ReportForm = (props) => {
  const [state, setState] = useState({
    data: null,
    isSubmitted: false,
    isError: false,
  });

  const { analytics } = props.firebase;

  const closeWebViewHandler = useCallback(() => {
    window.ReactNativeWebView.postMessage("close_webview");
  }, []);

  const submitForm = useCallback(
    (e) => {
      if (!window.MayoFactory.isRobot()) {
        e.preventDefault();
        let hasError = false;
        const submitButton = document.querySelector("form").submit;

        document.querySelectorAll("select").forEach((element) => {
          if (element.value === "") {
            element.classList.add("is-invalid");
            hasError = true;
          }
        });

        const formElements = document.querySelector("form").elements;
        const data = {};
        for (let i = 0; i < formElements.length; i++) {
          if (formElements[i].name) {
            data[formElements[i].name] = formElements[i].value;
          }
        }

        if (data["email"] !== data["emailConfirm"]) {
          hasError = true;
          document.querySelector("#emailConfirm").classList.add("is-invalid");
        }

        if (!hasError) {
          submitButton.setAttribute("disabled", "disabled");
          submitButton.innerText = "Processing...";

          const requestPayload = { ...state.data, ...data };

          axios
            .post("https://api-v2.heymayo.com/task/report", requestPayload, {
              headers: {
                Authorization: `Bearer ${state.data.authToken}`,
              },
            })
            .then((args) => {
              setState((prevState) => ({
                ...prevState,
                isSubmitted: true,
              }));
              closeWebViewHandler();

              analytics.logEvent("report_content", {
                report_type: requestPayload.reportType,
              });
            })
            .catch((err) => {
              setState((prevState) => ({
                ...prevState,
                isSubmitted: false,
                isError: true,
              }));

              submitButton.removeAttribute("disabled");
              submitButton.innerText = "Submit";
            });
        }
      }
    },
    [state.data, closeWebViewHandler, analytics]
  );

  useEffect(() => {
    window.setTitle("Report Content");
    analytics.logEvent("page_view");

    const params = new URL(document.location).searchParams;
    const data = params.get("data");

    if (data) {
      try {
        setState((prevState) => ({
          ...prevState,
          data: JSON.parse(data),
        }));
      } catch (err) {}
    }
  }, [analytics]);

  if (!state.data) {
    return null;
  }

  return (
    <div className="report">
      <nav>
        <img src={NAV_LOGO} alt="mayo" />
      </nav>
      <div className="container">
        {!state.isSubmitted && (
          <React.Fragment>
            <h1>Reporting content</h1>
            <p>
              In order for us to assist you better we need your valid email for
              follow ups if needed.
            </p>
            <form autoComplete="off" method="POST" onSubmit={submitForm}>
              <div className="form-group">
                <label htmlFor="email" className="isRequired">
                  Email Address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  defaultValue={state.data?.email}
                  required
                  autoFocus={true}
                />
                <div className="invalid-feedback">
                  Please provide a valid email address.
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="emailConfirm" className="isRequired">
                  Confirm Email Address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="emailConfirm"
                  name="emailConfirm"
                  defaultValue={state.data?.email}
                  required
                />
                <div className="invalid-feedback">Must be same as above.</div>
              </div>

              <div className="form-group">
                <label htmlFor="reason" className="isRequired">
                  Reason for reporting
                </label>
                <textarea
                  rows="5"
                  className="form-control"
                  id="reason"
                  name="reason"
                  resize="none"
                  required
                />
                <div className="invalid-feedback">
                  Please provide a valid reason.
                </div>
              </div>

              {state.isError && (
                <div style={{ color: "#ff3d1c" }}>Something went wrong.</div>
              )}

              <button id="submit" type="submit" className="btn">
                Submit
              </button>
            </form>
          </React.Fragment>
        )}
        {state.isSubmitted && (
          <div
            className="report-success"
            style={{ maxWidth: "100%", padding: 20, overflowX: "hidden" }}
          >
            <img className="report-success-icon" src={REPORT_ICON} alt="" />
            <p>
              Mayo is a kindness exchange platform. We believe every interaction
              should be a positive one.
            </p>
            <p>
              Thank you for reporting this content and helping ensure that Mayo
              remains a positive, safe, and welcoming community for everyone.
            </p>
            <p>
              We are looking into your report and will reach out if we need any
              additional info.
            </p>
            <button
              type="button"
              className="btn btn-bordered"
              onClick={closeWebViewHandler}
            >
              Done
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(withFirebase(ReportForm));
